<template>
  <div class="tree">
    <p class="title">
      建筑信息
    </p>
    <el-input v-model="filterText" placeholder="请输入关键字" class="input-with-search">
      <el-button slot="append" icon="el-icon-search" />
    </el-input>
    <div class="tree-wrap">
      <el-tree ref="tree"
               v-loading="loading"
               class="flow-tree"
               :data="treeData"
               node-key="buildingId"
               :default-expanded-keys="defaultKeys"
               highlight-current
               :props="{disabled:'forbidden'}"
               :filter-node-method="filterNode"
               @node-click="handleNodeClick">
        <span slot-scope="{ node }" :title="node.data.buildingName" class="tree-label">
          {{ node.data.buildingName }}
        </span>
      </el-tree>
    </div>
  </div>
</template>
<script>
export default {
  name: "JyTreeLeft",
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      filterText: "",
      treeData: [],
      defaultKeys: []
    }
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val)
    },
    data (val) {
      this.treeData = val
      this.generateDefaultKeys(this.defaultKeys, val)
    }
  },
  methods: {
    filterNode (value, data) {
      console.log("filterNode", value, data)
      if (!value) return true
      return data.buildingName.indexOf(value) !== -1
      // return this.fuzzyMatch(data.buildingName, value)
    },
    fuzzyMatch (source, target) {
      const regex = new RegExp(target, "i")
      return regex.test(source)
    },
    handleNodeClick (data, node) {
      this.$emit("nodeClick", data, node)
    },
    generateDefaultKeys (defaultKeys, treeData) {
      const menu = treeData[0]
      const subMenu = menu?.children

      if (menu) defaultKeys.push(menu.buildingId)
      if (subMenu) {
        for (const node of subMenu) {
          if (node.parentBuildingId === "0") {
            defaultKeys.push(node.buildingId)
          }
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.tree {
  width: 100%;
  min-width: 335px;
  height: 100%;
  padding: 20px 10px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e2e9fa;

  .title {
    padding-left: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    border-left: 3px solid $color-primary;
  }

  .input-with-search {
    margin-bottom: 20px;

    :deep(.el-input-group__append) {
      color: #fff;
      background-color: $color-primary;
      border-color: $color-primary;
    }
  }

  .tree-wrap {
    width: 100%;
    height: calc(100% - 93px);
    overflow: auto;
  }

  :deep(.flow-tree) {
    width: 100%;
    height: 100%;
    overflow-x: auto;
  }

  :deep(.flow-tree>.el-tree-node) {
    display: inline-block;
    min-width: 100%;
  }

  :deep(.el-tree-node__content) {
    height: 36px;
    padding-right: 15px;
  }

  .tree-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
