const PayRefundRecord = [
  {
    label: "处理描述",
    value: "content",
    type: "textarea",
    maxlength: 200,
    required: true
  },
  {
    label: "凭证",
    value: "voucherUrl",
    type: "upload",
    required: false
  }
]

const RefundManagement = [
  {
    label: "退款金额",
    value: "refundAmount"
  },
  {
    label: "备注",
    value: "content",
    type: "textarea",
    maxlength: 100
  },
  {
    label: "退费凭证",
    value: "voucherUrls",
    type: "upload",
    required: true
  }
]

const BuildManagement = [{
  label: "建筑名称",
  value: "buildingName",
  maxlength: 20
}, {
  label: "面积(㎡)",
  value: "areaSize",
  maxlength: 9
}, {
  label: "微信充值权限",
  value: "wxRechargeEnable",
  type: "switch"
}, {
  label: "微信退费权限",
  value: "wxRefundEnable",
  type: "switch"
}, {
  label: "人数",
  value: "personCount",
  maxlength: 6
}, {
  label: "关联设备",
  value: "deviceId",
  type: "select",
  defaultProps: { label: "deviceNumberTypeName", value: "deviceId" },
  clearable: "display"
}, {
  label: "租户信息",
  value: "tenantId",
  multiple: "multiple",
  type: "select",
  defaultProps: { label: "label", value: "userId" }
}]

const ElectricityMeter = [
  {
    label: "电表类型",
    value: "deviceType",
    type: "select"
  },
  {
    label: "能源类型",
    value: "energyType",
    type: "select"
  },
  {
    label: "电表号",
    value: "deviceNumber",
    maxlength: 25
  },
  {
    label: "CT变比",
    value: "ratio",
    maxlength: 6
  },
  {
    label: "最大功率(kW)",
    value: "maxPower",
    maxlength: 9
  },
  {
    label: "通讯类型",
    value: "comTypeList",
    type: "select",
    multiple: true
  },
  {
    label: "绑定建筑",
    value: "buildingId",
    type: "cascader",
    defaultProps: {
      label: "buildingName",
      value: "buildingId",
      disabled: "forbidden",
      checkStrictly: true
    }
  },
  {
    label: "绑定建筑",
    value: "buildingName"
  },
  {
    label: "预留金额",
    value: "initAmount",
    maxlength: 6
  },
  {
    label: "备注",
    value: "comment",
    type: "textarea",
    maxlength: 100
  }
]

const ElectricityPrice = [{
  label: "收费方案名称",
  value: "energyPriceName",
  maxlength: 20
}, {
  label: "能源类型",
  value: "energyType",
  type: "select"
}, {
  label: "电价类型",
  value: "priceType",
  type: "radio"
}, {
  label: "价格(元/kWh)",
  value: "energyPriceDetailRequest",
  type: "slot"
}]

const UserManagement = [
  { label: "用户名", value: "userName", maxlength: 20, placeholder: "不填写则自动生成" },
  { label: "手机号", value: "phoneNum", maxlength: 11 },
  { label: "姓名", value: "fullName", maxlength: 20, required: false },
  { label: "性别", value: "gender", type: "select" },
  { label: "电子邮箱", value: "email", maxlength: 50 },
  { label: "备注", value: "comment", type: "textarea", maxlength: 100 },
  { label: "选择角色", value: "roleIdList", type: "checkbox", defaultProps: { label: "roleName", value: "roleId" }}
]

const RateTimeConfig = [
  { label: "时段名称", value: "name", maxlength: 20 },
  { label: "时段", value: "period", type: "slot" }
]
export default { PayRefundRecord, RefundManagement, BuildManagement, ElectricityMeter, ElectricityPrice, UserManagement, RateTimeConfig }
