<template>
  <div class="jy-drawer-form-check">
    <el-form :model="form"
             :inline="inline"
             :disabled="true"
             :label-width="labelWidth">
      <el-form-item v-for="(item,index) in itemData"
                    :key="'form'+index"
                    :class="item.label==='noShow'?'noShow': ''"
                    :label="item.label+'：'">
        <el-input v-if="!item.type"
                  v-model="formData[item.value]" />
        <el-image v-if="item.type==='img'"
                  class="check_img"
                  :src="formData[item.value]"
                  :preview-src-list="srcList">
          <div slot="error" class="image-slot">
            <img src="../assets/imgs/check_qrcode.png">
          </div>
        </el-image>
        <el-image v-if="item.type==='imgPre'"
                  style="width: 120px;height: 120px;"
                  :src="formData[item.value]"
                  :preview-src-list="[formData[item.value]]">
          <div slot="error">
            -
            <!-- <img src="../assets/imgs/check_qrcode.png"> -->
          </div>
        </el-image>
        <el-input v-if="item.type==='textarea' && item.autosize !==15"
                  v-model="formData[item.value]"
                  :rows="item.rows?item.rows:4"
                  type="textarea"
                  :maxlength="100"
                  show-word-limit
                  :autosize="{ minRows: 5 }" />
        <el-input v-if="item.type==='textarea' && item.autosize===15"
                  v-model="formData[item.value]"
                  :rows="item.rows?item.rows:4"
                  type="textarea"
                  :maxlength="100"
                  show-word-limit
                  :autosize="{ minRows: 15 }" />
        <el-select v-if="item.type==='select'"
                   v-model="formData[item.value]"
                   placeholder="">
          <el-option v-for="(ops,opsIndex) in item.options"
                     :key="'select'+opsIndex"
                     :label="item.defaultProps?ops[item.defaultProps.label]:ops.label"
                     :value="item.defaultProps?ops[item.defaultProps.value]:ops.value" />
        </el-select>
        <!-- 这里如果需要switch换行，宽度给600px -->
        <el-switch v-if="item.type==='switch'"
                   v-model="formData[item.value]"
                   style="width: 150px;"
                   active-color="#13ce66"
                   inactive-color="#dcdfe6" />

        <el-checkbox-group v-if="item.type==='checkbox'"
                           v-model="formData[item.value]">
          <template v-if="item.options">
            <el-checkbox v-for="(ops,opsIndex) in item.options"
                         :key="'checkbox'+opsIndex"
                         :label="item.defaultProps?ops[item.defaultProps.value]:ops.value"
                         :title="item.defaultProps?ops[item.defaultProps.label]:ops.label">
              {{ item.defaultProps?ops[item.defaultProps.label]:ops.label }}
            </el-checkbox>
          </template>
          <template v-else>
            <el-checkbox v-for="(ops,opsIndex) in options[item.value]"
                         :key="'checkbox'+opsIndex"
                         :label="item.defaultProps?ops[item.defaultProps.value]:ops.value"
                         :title="item.defaultProps?ops[item.defaultProps.label]:ops.label">
              {{ item.defaultProps?ops[item.defaultProps.label]:ops.label }}
            </el-checkbox>
          </template>
        </el-checkbox-group>

        <el-table v-if="item.type==='table'"
                  :data="formData[item.value]"
                  header-cell-class-name="header-cell"
                  :header-cell-style="{'background-color': 'rgba(75,136,255,.2)', color: '#555','font-size':'14px'}">
          <el-table-column v-if="hasTableIndex"
                           key="inde"
                           type="index"
                           label="序号"
                           width="60" />
          <el-table-column v-for="(table,tIndex) in item.columns"
                           :key="'table'+tIndex"
                           :prop="table.prop"
                           :width="table.width"
                           :label="table.label" />
        </el-table>

        <div v-if="item.type==='inputWithImg'" class="input-bg">
          <div>{{ formData[item.value].createTime }}</div>
          <div>{{ formData[item.value].content }}</div>
          <el-image v-for="(child,cIndex) in formData[item.value].voucherUrls"
                    :key="item.value+'child'+cIndex"
                    :src="child"
                    :preview-src-list="[child]"
                    class="img" />
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import MockData from "../mock/drawer-form-checks"
export default {
  name: "JyDrawerFormCheck",
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    form: {
      type: Object,
      default: () => {
        return {}
      }
    },
    options: {
      type: Object,
      default: () => {
        return {}
      }
    },
    labelWidth: {
      type: String,
      default: "103px"
    },
    inline: {
      type: Boolean,
      default: false
    },
    hasTableIndex: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      itemData: [],
      formData: {}
    }
  },
  watch: {
    form (val) {
      this.formData = val
    },
    formData: function (val, oldVal) {
      console.log("formData", val, oldVal)
      // web微信充值权限
      if (val?.wxRechargeEnable === 1) {
        val.wxRechargeEnable = true
      } else {
        val.wxRechargeEnable = false
      }
      // web微信退费权限
      if (val?.wxRefundEnable === 1) {
        val.wxRefundEnable = true
      } else {
        val.wxRefundEnable = false
      }
    }
  },
  mounted () {
    this.itemData = this.data.length > 0 ? this.data : MockData[this.$route.name]
    this.formData = this.form
  }
}
</script>
<style lang="scss" scoped>
.jy-drawer-form-check {
  height: 100%;

  .el-form {
    height: 100%;

    .el-form-item {
      margin-bottom: 20px;
    }
  }

  :deep(.el-checkbox__label) {
    width: 160px;
    padding-bottom: 5px;
    overflow: hidden;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
  }

  .input-bg {
    min-height: 32px;
    padding: 5px 15px;
    font-size: 14px;
    line-height: 1.5;
    color: #777;
    cursor: not-allowed;
    background-color: #EFEFEF;
    border: 1px solid #e4e7ed;
    border-color: #D2D2D2;
    border-radius: 4px;

    .img {
      width: 6.25vw;
      height: 11vh;
      margin-top: 10px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  // 查看页面-置灰的字段颜色是#777
  :deep(.el-input.is-disabled .el-input__inner) {
    color: #777;
  }

  // 查看页面-置灰的字段颜色是#777
  :deep(.el-textarea.is-disabled .el-textarea__inner) {
    color: #777;
    background-color: #efefef;
    border-color: #d2d2d2;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.check_img {
  width: 80px;
  height: 80px;
  // margin-top: 5px;
  margin-bottom: -13px;
}

.noShow {
  margin-top: 20px;

  >:deep(.el-form-item__label) {
    display: none;
  }

  :deep(.el-form-item__content) {
    height: 100%;
    margin-left: 0 !important;
  }
}
</style>
