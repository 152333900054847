<template>
  <div class="jy-page-content-layout">
    <div id="page-header" class="page__header">
      <div v-if="pageTitle"
           class="page__header-title">
        {{ pageTitle }}
      </div>
      <slot name="header" />
    </div>
    <div class="page__body" :style="{height:height}">
      <slot name="body" />
    </div>

    <!-- 列表页中的弹窗组件 -->
    <div class="page__dialog">
      <slot name="dialog" />
    </div>
  </div>
</template>

<script>
const elementResizeDetectorMaker = require("element-resize-detector")
export default {
  name: "JyPageContentLayout",
  props: {
    pageTitle: {
      type: String,
      default: ""
    }
  },
  data () {
    return {
      height: ""
    }
  },
  mounted () {
    this.watchSize()
  },
  methods: {
    watchSize () {
      const erd = elementResizeDetectorMaker()
      erd.listenTo(document.getElementById("page-header"), (element) => {
        const headerHeight = element.offsetHeight + 20
        this.height = `calc(100% - ${headerHeight}px)`
      })
    }
  }
}
</script>

<style scoped lang="scss">
.jy-page-content-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e2e9fa;
}

.page__header {
  margin-bottom: 20px;
  border-bottom: solid 1px #d2d2d2;

  &-title {
    // height: 16px;
    padding-left: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    // line-height: 16px;
    color: #333;
    border-left: 3px solid $color-primary;
  }
}

.page__body {
  display: flex;
  flex-direction: column;
  gap: 20px;

  :deep(.button) {
    text-align: right;
  }
}
</style>
